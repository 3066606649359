import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content, ui, adaptive, media } from '../../theme/base'
import { Carousel } from 'react-responsive-carousel'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(80), 0],
    position: 'relative',
    [adaptive]: {
      padding: [rem(48), 0],
    },
  },
  content,
  title: {
    ...ui.title,
    marginTop: rem(10),
  },
  icon: {
    width: rem(24),
    height: rem(24),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  content: {
    ...content,
    paddingLeft: 0,
    paddingRight: 0,
  },
  slider: {
    maxWidth: rem(1520),
    margin: [rem(40), 'auto', 0],
    textAlign: 'center',
    overflow: 'hidden',
    [adaptive]: {
      width: 'auto',
      marginTop: rem(24),
    },
    '& .slider': {
      marginLeft: 'auto',
      marginRight: 'auto',
      whiteSpace: 'nowrap',
      // transition: 'transform 300ms ease',
    },
    '& .slide': {
      marginTop: rem(80),
      paddingLeft: rem(8),
      paddingRight: rem(8),
      textAlign: 'right',
      display: 'inline-block',
      verticalAlign: 'top',
      opacity: 0.5,
      transition: 'opacity 300ms ease',
      [adaptive]: {
        paddingLeft: rem(4),
        paddingRight: rem(4),
        marginTop: 0,
      },
      '&.selected': {
        marginTop: 0,
        textAlign: 'center',
        opacity: 1,
        '& img': {
          width: rem(624),
          [adaptive]: {
            width: '91.4666vw',
          },
        },
        '& + .slide': {
          textAlign: 'left',
        },
      },
      '& img': {
        width: rem(416),
        [adaptive]: {
          width: '91.4666vw',
        },
      },
    },
    '& .control-arrow': {
      background: 'none',
      width: rem(64),
      height: rem(64),
      position: 'absolute',
      top: rem(175),
      cursor: 'pointer',
      zIndex: '2',
      [adaptive]: {
        display: 'none',
      },
      '&.control-prev': {
        background: `url(${require('./btn-prev.svg')}) no-repeat`,
        // left: rem(-32),
        left: rem(176),
      },
      '&.control-next': {
        background: `url(${require('./btn-next.svg')}) no-repeat`,
        // right: rem(-32),
        right: rem(176),
      },
      '& img': {
        width: 'inherit',
        height: 'inherit',
        display: 'block',
      },
    },
    '& .control-dots': {
      marginTop: rem(16),
      textAlign: 'center',
      position: 'relative',
    },
    '& .dot': {
      // TODO: add '#bac7e2' to theme.colors
      background: '#bac7e2',
      width: rem(12),
      height: rem(6),
      marginLeft: rem(3),
      marginRight: rem(3),
      borderRadius: rem(4),
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer',
      transition: 'background 300ms ease',
      '&.selected': {
        background: theme.colors.blue,
      }
    }
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 'auto',
    display: 'block',
    position: 'relative',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  text: {
    ...ui.text,
    maxWidth: rem(342),
    marginTop: rem(16),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    fontWeight: 600,
    textAlign: 'center',
    // display: 'inline-block',
    // verticalAlign: 'top',
    marginLeft: 'auto',
    marginRight: 'auto',
    whiteSpace: 'normal',
    [adaptive]: {
      paddingLeft: rem(8),
      paddingRight: rem(8),
    },
  },

  // ARROWS TEMPLATE
  // arrow: {
  //   width: rem(64),
  //   height: rem(64),
  //   position: 'absolute',
  //   top: rem(175),
  //   cursor: 'pointer',
  //   [adaptive]: {
  //     display: 'none',
  //   },
  //   '&.prev': {
  //     // left: rem(-32),
  //     left: rem(176),
  //   },
  //   '&.next': {
  //     // right: rem(-32),
  //     right: rem(176),
  //   },
  //   '& img': {
  //     width: 'inherit',
  //     height: 'inherit',
  //     display: 'block',
  //   },
  // },

  // DOTS TEMPLATE
  // nav: {
  //   marginTop: rem(16),
  //   textAlign: 'center',
  //   position: 'relative',
  // },
  // dot: {
  //   // TODO: add '#bac7e2' to theme.colors
  //   background: '#bac7e2',
  //   width: rem(12),
  //   height: rem(6),
  //   marginLeft: rem(3),
  //   marginRight: rem(3),
  //   borderRadius: rem(4),
  //   display: 'inline-block',
  //   verticalAlign: 'top',
  //   cursor: 'pointer',
  //   transition: 'background 300ms ease',
  //   '&.active': {
  //     background: theme.colors.blue,
  //   }
  // }
}

const SLIDES = [
  { image: 'slide-2-1', text: 'Chat to experts on the go wherever you are' },
  { image: 'slide-2-2', text: 'Exchange photos, videos and files with experts' },
  { image: 'slide-2-3', text: 'Get live progress notifications to stay on top of your tasks' },
  { image: 'slide-2-4', text: 'Work with different experts on as many tasks as you wish' },
  { image: 'slide-2-5', text: 'The chat is 100% secure and encrypted' },
]

const OPTIONS = {
  showThumbs: false,
  showStatus: false,
  infiniteLoop: true,
  centerMode: true,
  showArrows: true,
  showIndicators: true,
  autoPlay: true,
  interval: 3000,
  centerSlidePercentage: window.innerWidth >= media.desktop ? 62.5 : 100,
  transitionTime: 300,
}

const Messenger = ({ classes }) =>
  <div className={classes.root}>
    <div className={classes.content}>
      <img className={classes.icon} src={require('./mini-logo@3x.png')} alt='' />
      <div className={classes.title}>Why use Collaborative Tech Messenger?</div>
    </div>
    <div className={classes.slider}>
      <div className={classes.content}>
        <Carousel {...OPTIONS}>
          {SLIDES.map(({ image, text}, index) => 
            <div className={classes.item} key={index}>
              <picture className={classes.image}>
                <source
                  media={`(min-width: ${media.desktop}px)`}
                  srcSet={`
                    ${require(`./${image}@3x.jpg`)} 3x, 
                    ${require(`./${image}@2x.jpg`)} 2x, 
                    ${require(`./${image}@1x.jpg`)} 1x
                  `}
                />
                <img 
                  src={require(`./${image}-mobile@1x.jpg`)}
                  srcSet={`
                    ${require(`./${image}-mobile@3x.jpg`)} 3x, 
                    ${require(`./${image}-mobile@2x.jpg`)} 2x, 
                    ${require(`./${image}-mobile@1x.jpg`)} 1x
                  `}
                  alt=''
                />
              </picture>
              <div className={classes.text}>{text}</div>
          </div>
          )}
        </Carousel>

        {/* ARROWS TEMPLATE */}
        {/* <div className={classnames(classes.arrow, 'prev')}>
          <img src={require('./btn-prev.svg')} alt='' />
        </div>
        <div className={classnames(classes.arrow, 'next')}>
          <img src={require('./btn-next.svg')} alt='' />
        </div> */}

        {/* DOTS TEMPLATE */}
        {/* <div className={classes.nav}>
          <div className={classnames(classes.dot, 'active')} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
        </div> */}

      </div>
    </div>
  </div>

export default injectSheet(styles)(Messenger)