import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, adaptive } from './../../theme/base'

const styles = {
  root: {
    background: '#6c3eff',
    height: rem(56),
    padding: [rem(16), rem(24)],
    textAlign: 'center',
    color: theme.colors.white,
    fontSize: rem(20),
    lineHeight: rem(24),
    letterSpacing: rem(0.12),
    position: 'relative',
    zIndex: 2,
    [adaptive]: {
      minHeight: rem(64),
      height: 'auto',
      padding: [rem(8), 0],
      fontSize: rem(16),
    },
    '& img': {
      width: 'auto',
      height: rem(24),
      margin: [0, rem(4)],
      display: 'inline-block',
      verticalAlign: 'top',
      transform: `translateY(${rem(-2)})`,
      [adaptive]: {
        height: rem(20),
        transform: 'none',
      },
    },
    '& br': {
      display: 'none',
      [adaptive]: {
        display: 'block',
      },
    },
  },
  text: {
    margin: [0, 'auto'],
    [adaptive]: {
      maxWidth: rem(375),
      padding: [0, rem(16)],
    },
  },
}

const Banner = ({ classes }) => 
  <div className={classes.root}>
    <div className={classes.text}>
      Collaborative Tech <img src={require('./heart.png')} alt='' /> you!
      {' '}
      Sign up and get $10 worth of&nbsp;expert help.
      {' '}
      Use them for what’s best for you!
    </div>
  </div>

export default injectSheet(styles)(Banner)