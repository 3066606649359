export const FAQ = [
  {
    icon: 'contextMenu-support',
    title: 'How it works?',
    content: 'You send a brief description of what you need help with or simple make a photo of the task you are stuck on. Choose how quickly you need help from freelance expert and how much time a freelance expert should spend on it. Now you are all set! We will find the best matching freelance expert for your problem to meet your deadline.',
  },
  {
    icon: 'contextMenu-support',
    title: 'What if time goes out?',
    content: 'If freelance expert needs more time to help you with your request she will let you know at the very beginning. Otherwise you can assume that the time is sufficient. You can always extend your time. Also remember that you can chat to you freelance expert for up to 24 hours after you are done with your request to clarify anything. Its free!',
  },
  {
    icon: 'contextMenu-support',
    title: 'With what type of tasks an expert can help?',
    content: 'We have freelance experts in almost every topic, so give it a shot and we are confident that our experts can help.',
  },
  {
    icon: 'contextMenu-support',
    title: 'How to cancel request?',
    content: 'You can request a cancelation or a refund from our Customer Support. They will be glad to assist.',
  },
  {
    icon: 'contextMenu-support',
    title: 'How much does it cost?',
    content: 'Our freelance experts charge per hour. The minimum amount of time you can request is 30 minutes. The price depends on how quickly need help with and starts from hist $12.5 for 30 minutes.',
  },
]
