import React, { Fragment, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { render } from 'react-dom'
import TagManager from 'react-gtm-module'
import hotjar from './hotjar'
import * as Sentry from '@sentry/browser'
import lrtracker from './lrtracker'

import MainLandingPage from './landings/v1'
//import EarlyAccessLandingPage from './landings/v2'
//import TutoringLandingPage from './landings/v3'

import {
  PrivacyPolicy,
  TermsAndConditions,
} from './landings/pages'

// import AuthPhonePage from './components/AuthPhonePage'
// import AuthEmailPage from './components/AuthEmailPage'
// import TutorsPage from './pages/TutorsPage'
// import OrdersPage from './pages/OrdersPage'
// import OrderRedirectPage from './pages/OrderRedirectPage'
// import OrderChatPage from './pages/OrderChatPage'
import NotFoundPage from './pages/404Page'
import MaintenancePage from './pages/500Page'
import ConnectingPage from './pages/ConnectingPage'
// import PaymentResult from './pages/PaymentResult'
import AskPhonePage from './pages/AskPhonePage'
import {
  modals,
  selectors,
  Message,
} from '@verdaccio/mt-uikit'
import Notifications from './components/Notifications'

import { Provider, useSelector, useDispatch } from 'react-redux'
import store from './store'

import './assets/bootstrap.min.css'
import './assets/reset.css'
import './assets/styles.scss'

import ScrollToTopOnMount from './containers/ScrollToTopOnMount'

import { ordersSelector } from './nerdytutors'

const OfflineMessage = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const t = setTimeout(() => setShow(true), 3000)
    return () => clearTimeout(t)
  }, [])
  return show ? <Message.Offline /> : null
}

/*const ConnectRequiredRouter = ({ component, logged, ready, offline, askPhone, ...rest }) => <Route {...rest} render={
  props => {
    const { location } = props
    const redirect = location.pathname === '/order/new' ? '/signup' : '/login'
    return logged
      ? ready
        ? askPhone
          ? <AskPhonePage />
          : <Fragment>
            {React.createElement(component, props)}
            {offline && <OfflineMessage />}
          </Fragment>
        : React.createElement(ConnectingPage)
      : <Redirect to={{ pathname: redirect, state: { from: location } }} />
  }
} />*/

const NoAuthRequiredRouter = ({ component, logged, ...rest }) => <Route {...rest} render={
  ({ location }) => logged
    ? <Redirect to={{ pathname: '/orders', state: { from: location } }} />
    : React.createElement(component)
} />

const Manager = modals.createManager({ useSelector, useDispatch })

const App = () => {
  const dispatch = useDispatch()
  const auth = useSelector(selectors.authSelector)
  const status = useSelector(selectors.xmppSelector)
  const nodes = useSelector(selectors.nodesSelector)
  const { profile } = useSelector(selectors.authSelector)
  const orders = useSelector(ordersSelector)



  const offline = auth.cid ? status !== 'CONNECTED' : false
  const ready = nodes[auth.ordersNode] !== undefined
  const askPhone = profile && !profile.phone && orders.filter(o => o.status !== 'draft').length > 0

  const appStatus = {
    logged: auth.cid,
    offline,
    ready,
    askPhone
  }

  // нужно синхронизировать логику реконекта с tutortool пока не поместим это в библиотеку
  useEffect(() => {
    let timeout = null
    if (auth.cid && !status) {
      dispatch({ type: 'CONNECT', payload: auth })
    }
    if (auth.cid && status === 'DISCONNECTED') {
      const randTimeout = Math.floor(Math.random() * 10000) + 3000
      timeout = setTimeout(() => dispatch({ type: 'CONNECT', payload: auth }), randTimeout)
    }
    return () => clearTimeout(timeout)
  }, [status, auth])

  useEffect(() => { if (auth.cid) { lrtracker.config.setCid(auth.cid) } }, [auth])

  const skipScrollRoutes = ['/order/:orderid/:tab']

  return <Fragment>
    <Notifications />
    <Router>
      <ScrollToTopOnMount skipScrollRoutes={skipScrollRoutes}>
        <Switch>
          <NoAuthRequiredRouter exact path='/' {...appStatus} component={MainLandingPage} />
{/*
          <NoAuthRequiredRouter exact path='/tutoring-help-1' {...appStatus} component={TutoringLandingPage} />
          <NoAuthRequiredRouter exact path='/10-OFF-yt1' {...appStatus} component={TutoringLandingPage} />
          <NoAuthRequiredRouter exact path='/homework-help-1' {...appStatus} component={MainLandingPage} />
          <NoAuthRequiredRouter exact path='/homework-help-2' {...appStatus} component={MainLandingPage} />
          <NoAuthRequiredRouter exact path='/10-OFF-yt2' {...appStatus} component={MainLandingPage} />
          <NoAuthRequiredRouter exact path='/landing-page-2' {...appStatus} component={MainLandingPage} />
          <NoAuthRequiredRouter exact path='/early-access' {...appStatus} component={EarlyAccessLandingPage} />
          <NoAuthRequiredRouter exact path='/login' {...appStatus} component={AuthEmailPage.Login} />
          <NoAuthRequiredRouter exact path='/restore' {...appStatus} component={AuthEmailPage.Restore} />
          <NoAuthRequiredRouter exact path='/signup' {...appStatus} component={AuthEmailPage.Signup} />
          <ConnectRequiredRouter exact path='/orders' {...appStatus} component={OrdersPage} />
          <ConnectRequiredRouter exact path='/ask-phone' {...appStatus} component={AskPhonePage} />
          <ConnectRequiredRouter exact path='/orderschat' {...appStatus} component={OrdersPage} />
          <ConnectRequiredRouter exact path='/tutors' {...appStatus} component={TutorsPage} />
          <ConnectRequiredRouter exact path='/order/:orderState(new|draft|extend)' {...appStatus} component={OrderRedirectPage} />
          <ConnectRequiredRouter exact path='/order/:orderid/:tab(chat|timeline)' {...appStatus} component={OrderChatPage} />
          <Route exact path='/payment-success/:orderid' component={PaymentResult.Success} />
          <Route exact path='/payment-failed/:orderid/:orderState(draft|extend)' component={PaymentResult.Failed} />
          <Route exact path='/payment-cancel/:orderid/:orderState(draft|extend)' component={PaymentResult.Cancel} />
*/}
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
          <Route exact path='/500' component={MaintenancePage} />
          <Route component={NotFoundPage} />
        </Switch>
        <Manager />
      </ScrollToTopOnMount>
    </Router>

  </Fragment>
}

if (ENV.GTM_ID) {
  TagManager.initialize({ gtmId: ENV.GTM_ID })
}

if (ENV.HOTJAR) {
  hotjar()
}

if (ENV.SENTRY) {
  Sentry.init({ dsn: ENV.SENTRY })
}

if (lrtracker) {
  lrtracker.metrics.trackLocation()
  lrtracker.metrics.trackGoogleClientId()
}

localStorage.setItem('_mt-location', localStorage.getItem('_mt-location') || window.location.toString())

render(
  <Provider store={store}>
    <App />
  </Provider >,
  document.getElementById('root')
)
