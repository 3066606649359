import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content, ui, adaptive } from './../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(80), 0],
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(16)],
    },
  },
  content,
  title: {
    ...ui.title,
    [adaptive]: {
      ...ui.title[adaptive],
      textAlign: 'left',
    },
  },
  list: {
    marginTop: rem(56),
    position: 'relative',
  },
  item: {
    width: '33.33%',
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      width: '100%',
    },
    '& + &': {
      paddingLeft: rem(16),
      [adaptive]: {
        marginTop: rem(32),
        paddingLeft: 0,
      },
    },
  },
  image: {
    width: 'auto',
    height: rem(40),
    display: 'block',
  },
  subtitle: {
    ...ui.subtitle,
    marginTop: rem(16),
  },
  text: {
    ...ui.text,
    marginTop: rem(16),
  },
}

const Superpower = ({ classes }) =>
  <div className={classes.root}>
    <div className={classes.content}>
      <div className={classes.title}>What is the superpower of Collaborative Tech?</div>
      <ul className={classes.list}>
        <li className={classes.item}>
          <img src={require('./ic-tutors.svg')} alt='' />
          <div className={classes.subtitle}>We connect you to the best experts in minutes</div>
          <div className={classes.text}>You don’t need to spend a lot of time to search for the right expert</div>
        </li>
        <li className={classes.item}>
          <img src={require('./ic-safe.svg')} alt='' />
          <div className={classes.subtitle}>We work around your deadline</div>
          <div className={classes.text}>We know how important it is to meet your deadlines. We only match you with experts who will get it done on time</div>
        </li>
        <li className={classes.item}>
          <img src={require('./ic-defices.svg')} alt='' />
          <div className={classes.subtitle}>1000s of experts are always at your fingertips</div>
          <div className={classes.text}>Try our Collaborative Tech Messenger and chat with experts on the go</div>
        </li>
      </ul>
    </div>
  </div>

export default injectSheet(styles)(Superpower)