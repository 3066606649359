import React from 'react'
import injectSheet from 'react-jss'
import { Button } from '@verdaccio/mt-uikit'
import theme, { rem, content, ui, adaptive } from '../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(80), 0],
    textAlign: 'center',
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(8)],
    },
    '& button': {
      width: rem(343),
      marginTop: rem(16),
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: rem(16),
      [adaptive]: {
        width: '100%',
      },
    },
  },
  content,
  title: {
    ...ui.title,
  },
}

const Try = ({ 
  onScrollToForm, 
  classes, 
}) =>
  <div className={classes.root}>
    <div className={classes.content}>
      <div className={classes.title}>30 seconds is all it takes to get a perfect expert</div>
      <Button 
        className={classes.button}
        type='primary'
        size='large'
        onClick={onScrollToForm}
      >
        Get Started Now
      </Button>
    </div>
  </div>

export default injectSheet(styles)(Try)