import React from 'react'
import { Helmet } from 'react-helmet'
import injectSheet from 'react-jss'
import theme from './../theme/base'

import Hero1 from './../sections/Hero1'
import Next from './../sections/Next'
import Superheroes from './../sections/Superheroes'
import Superpower from './../sections/Superpower'
import Messenger from './../sections/Messenger'
import Payment from './../sections/Payment'
import Try from './../sections/Try'
//import Ask from './../sections/Ask'
//import Testimonials from './../sections/Testimonials'
import Great from './../sections/Great'
import Faq from './../sections/Faq'
import Footer from './../sections/Footer'
import { MiniOrderForm } from './../components'
import { useLocation } from 'react-router-dom'
import { getGeekNumber } from './../../utils/geekNumGenerators'
import getSEO from './seo'

const styles = {
  root: {
    background: theme.colors.white,
  },
}

const Landing = ({ classes }) => {
  const { pathname } = useLocation()
  const count = getGeekNumber()

  const onScrollToForm = () => window.scrollTo({
    top: 165,
    behavior: 'smooth',
  })

  const text = `<strong>${count} experts</strong> are ready to help you right <strong>NOW!</strong>`
  const { title, description, keywords } = getSEO()

  const buttonText = pathname === '/homework-help-2'
    ? 'Get Help'
    : 'Get an expert'

  return <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
    <div className={classes.root}>
      <Hero1 onScrollToForm={onScrollToForm} showLogin>
        <MiniOrderForm
          buttonText={buttonText}
          placeholders={[
            'Name your task',
            'Select your subject name'
          ]}
        />
      </Hero1>
      <Next text={text} />
      <Superheroes submitTitle='Try it now!' onScrollToForm={onScrollToForm} />
      <Superpower />
      <Messenger />
      <Payment />
      <Try onScrollToForm={onScrollToForm} />
      {/*<Ask />*/}
      {/*<Testimonials />*/}
      <Great
        text={text}
        submitTitle={buttonText}
        onScrollToForm={onScrollToForm}
      />
      <Faq />
      <Footer />
    </div>
  </>
}

export default injectSheet(styles)(Landing)