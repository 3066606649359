import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import injectSheet from 'react-jss'
import { Button } from '@verdaccio/mt-uikit'
import theme, { rem, adaptive } from '../../theme/base'

const styles = {
  root: {
    background: 'rgba(0, 0, 0, 0.9)',
    width: rem(360),
    padding: [rem(20), rem(16), rem(24), rem(60)],
    position: 'fixed',
    bottom: rem(40),
    left: rem(40),
    borderRadius: rem(14),
    zIndex: 30000,
    [adaptive]: {
      width: 'auto',
      bottom: rem(8),
      left: rem(8),
      right: rem(8),
    },
    '& img': {
      width: rem(30),
      height: rem(30),
      position: 'absolute',
      top: rem(24),
      left: rem(16),
    },
    '& button': {
      width: rem(148),
      height: rem(40),
      padding: [rem(10), 0, rem(6)],
      marginTop: rem(16),
      boxShadow: 'none',
      borderRadius: rem(28),
    },
  },
  text: {
    fontSize: rem(14),
    lineHeight: rem(28),
    letterSpacing: rem(0.08),
    color: theme.colors.white,
    '& a': {
      color: theme.colors.blue,
      fontWeight: 600,
    },
  },
}

const cookies = new Cookies()
const cookieName = '_mt-cookie-ask'

const CookiePopUp = ({ classes }) => {

  const [isGDRPCountry, setIsGDRPCountry] = useState(null)
  const [isGDRPAsked, setIsGDRPAsked] = useState(cookies.get(cookieName))

  useEffect(() => {
    if (!isGDRPAsked) {
      axios.get('//ana.dsh-agency.com/ipinfo/me?geo=1')
        .then(result => setIsGDRPCountry(['US', 'CA', 'AU'].indexOf(result.data.country_code) === -1))
        .catch(_ => setIsGDRPCountry(true))
    }
  }, [])

  const onSetIsGDRPAsked = () => {
    cookies.set(cookieName, 1, 365)
    setIsGDRPAsked(true)
  }

  return isGDRPCountry && !isGDRPAsked
    ? (
      <div className={classes.root}>
        <img src={require('./ic-cookies.svg')} alt='' />
        <div className={classes.text}>
          We use cookies to improve your experience
          and deliver personalized content. By using
          Collaborative Tech, you agree to our {' '}
          <Link to='/privacy-policy'>Privacy policy</Link>.
      </div>
        <Button
          className={classes.button}
          type='primary'
          size='medium'
          block
          onClick={onSetIsGDRPAsked}
        >
          Got it
      </Button>
      </div>
    )
    : null
}

export default injectSheet(styles)(CookiePopUp)