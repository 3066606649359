import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content, ui, adaptive } from './../../theme/base'

const styles = {
  root: {
    background: theme.colors.paleGrey,
    padding: [rem(80), 0],
    textAlign: 'center',
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(8)],
    },
  },
  content,
  title: {
    ...ui.title,
    paddingBottom: rem(8),
  },
  subtitle: {
    ...ui.subtitle,
  },
  text: {
    ...ui.text,
  },
  p: {
    ...ui.p,
    '&:first-child': {
      paddingTop: rem(8),
    },
  },
  item: {
    marginTop: rem(40),
    position: 'relative',
    [adaptive]: {
      marginTop: rem(24),
    },
  },
  icon: {
    width: rem(48),
    height: rem(48),
    marginTop: rem(12),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  block: {
    background: theme.colors.white,
    width: rem(172),
    padding: [rem(24), rem(16)],
    borderRadius: rem(12),
    boxShadow: `0 ${rem(16)} ${rem(40)} 0 rgba(0, 53, 136, 0.15), 0 ${rem(8)} ${rem(12)} 0 rgba(0, 38, 96, 0.06)`,
    display: 'inline-block',
    verticalAlign: 'top',
    '& span': {
      fontSize: rem(18),
      lineHeight: rem(24),
      letterSpacing: rem(0.11),
      color: theme.colors.slateGrey,
      display: 'block',
    },
    '& strong': {
      paddingTop: rem(4),
      paddingBottom: rem(2),
      fontSize: rem(40),
      lineHeight: rem(48),
      letterSpacing: rem(0.24),
      fontWeight: 500,
      color: theme.colors.charcoalGrey,
      display: 'block',
    },
  },
  helper: {
    width: rem(276),
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      width: 'auto',
      minHeight: rem(168),
      display: 'block',
      paddingLeft: rem(188),
      '& > div': {
        fontSize: rem(14),
        textAlign: 'left',
        '&:first-child': {
          marginTop: 0,
          paddingTop: 0,
        },
      },
    },
  },
  icons: {
    marginTop: rem(10),
    marginBottom: rem(16),
    '& img': {
      width: 'auto',
      height: rem(28),
      display: 'inline-block',
      verticalAlign: 'top',
      '& + img': {
        marginLeft: rem(16),
        [adaptive]: {
          marginLeft: rem(6),
        },
      },
    },
  },
}

const Payment = ({ classes }) =>
  <div className={classes.root}>
    <div className={classes.content}>
      <div className={classes.title}>Payment</div>
      <div className={classes.subtitle}>Yep, we don't like service subscriptions too</div>
      <div className={classes.text}>You will only pay for the actual session with an expert duration</div>
      <div className={classes.item}>
        <div className={classes.block}>
          <span>Starting at</span>
          <strong>$12.5</strong>
          <span>for 30 minutes</span>
        </div>
        {/* <div className={classes.helper}>
          <div className={classes.p}>We pre-authorise the full amount from your card.</div>
          <div className={classes.p}>But we only charge you when you successfully complete working with the tutor.</div>
        </div> */}
      </div>
      <div className={classes.item}>
        <div className={classes.subtitle}>100% Money Back Guarantee</div>
        {/* <div className={classes.text}>100% money back if the tutor can't fulfil your request</div> */}
        <img className={classes.icon} src={require('./ic-compliance.svg')} alt='' />
      </div>
      <div className={classes.item}>
        <div className={classes.subtitle}>You can pay using</div>
        <div className={classes.icons}>
          <img src={require('./ic-card-applepay.svg')} alt='' />
          <img src={require('./ic-card-googlepay@3x.jpg')} alt='' />
          <img src={require('./ic-card-paypal.svg')} alt='' />
          <img src={require('./ic-master-card.svg')} alt='' />
          <img src={require('./ic-visa.svg')} alt='' />
          <img src={require('./ic-amex.svg')} alt='' />
        </div>
        <div className={classes.text}>And other credit, debit and prepaid cards</div>
      </div>
    </div>
  </div>

export default injectSheet(styles)(Payment)