import React from 'react'
import injectSheet from 'react-jss'
import Header from './../Header'
import theme, { rem, content, ui, adaptive } from '../../theme/base'
import { Banner } from '../../components'

const styles = {
  root: {
    backgroundImage: 'linear-gradient(248deg, #3cb4ff, var(--blue))',
    paddingBottom: rem(32),
    borderRadius: [0, 0, rem(28), rem(28)],
    position: 'relative',
    [adaptive]: {
      paddingBottom: rem(8),
    },
  },
  content,
  wrapper: {
    paddingTop: rem(130),
    position: 'relative',
    [adaptive]: {
      paddingTop: rem(100),
    },
  },
  header: {
    display: 'block',
  },
  title: {
    ...ui.title,
    color: theme.colors.white,
  },
  p: {
    ...ui.p,
    marginTop: rem(8),
    color: theme.colors.white,
    textAlign: 'center',
  },
  bubble1: {
    width: rem(52),
    height: rem(43),
    display: 'block',
    position: 'absolute',
    top: rem(171),
    left: rem(25),
    [adaptive]: {
      display: 'none',
    },
  },
  bubble2: {
    width: rem(80),
    height: rem(64),
    display: 'block',
    position: 'absolute',
    bottom: rem(120),
    left: rem(106),
    [adaptive]: {
      left: 0,
      top: rem(169),
      bottom: 'auto',
    },
  },
  bubble3: {
    width: rem(146),
    height: rem(114),
    display: 'block',
    position: 'absolute',
    top: rem(90),
    right: rem(140),
    [adaptive]: {
      width: rem(97),
      height: 'auto',
      top: rem(36),
      right: 'auto',
      left: '50%',
      transform: `translateX(${rem(50)})`,
    },
  },
  bubble4: {
    width: rem(72),
    height: rem(56),
    display: 'block',
    position: 'absolute',
    bottom: rem(57),
    right: rem(65),
    [adaptive]: {
      display: 'none',
    },
  },
}

const Hero = ({ 
  classes,
  children,
  showLogin,
}) => 
  <div className={classes.root}>
    <Banner />
    <div className={classes.content}>
      <img className={classes.bubble1} src={require('./bubble-1.svg')} alt='' />
      <img className={classes.bubble2} src={require('./bubble-2.svg')} alt='' />
      <img className={classes.bubble3} src={require('./bubble-3.svg')} alt='' />
      <img className={classes.bubble4} src={require('./bubble-4.svg')} alt='' />
      <Header 
        showLogin={showLogin} 
        customRelativeClassName={classes.header} 
      />
      <div className={classes.wrapper}>
        <div className={classes.title}>Need an expert?</div>
        <div className={classes.p}>Live Chat with the expert anytime to solve it</div>
        {children}
      </div>
    </div>
  </div>

export default injectSheet(styles)(Hero)