import React from 'react'
import injectSheet from 'react-jss'
import { useSelector } from 'react-redux'
import { selectors } from '@verdaccio/mt-uikit'
import Header from './../../sections/Header'
import Footer from './../../sections/Footer'
import { Title, Subtitle, Paragraph, List, Item, Blockquote } from './../../layout'
import theme, { rem, content, ui, adaptive } from '../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
  },
  wrapper: {
    paddingTop: rem(136),
    paddingBottom: rem(80),
    [adaptive]: {
      paddingTop: rem(120),
      paddingBottom: rem(64),
    },
  },
  content: {
    ...content,
    width: rem(624),
    paddingLeft: 0,
    paddingRight: 0,
    [adaptive]: {
      width: '100%',
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },
}

const TermsAndConditions = ({ classes }) => {
  const auth = useSelector(selectors.authSelector)
  const isAuth = auth && auth.cid
  return (
    <div className={classes.root}>
      <Header showLogin={!isAuth} hasFixedState />
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Title>Terms and Conditions</Title>

          <Blockquote>
            <Paragraph>By visiting this web site, you agree to be bound by the website's Collaborative-Tech.co Terms of Use and all relatable U.S. laws and regulations. You also agree that you bear full responsibility for your compliance with any relatable local laws and regulations.</Paragraph>
            <Paragraph>If you do not accept any provisions of these Terms, we prohibit you from using, reading or otherwise facilitating this website.</Paragraph>
            <Paragraph>These Terms become effective upon the User from the time of their accession. The Terms are valid indefinitely after the first accession mentioned before. In case that one or more provisions of these Terms become non-valid, unenforceable or unrelated for any reason, this will not have an effect on the validity or applicability of the remaining provisions.</Paragraph>
          </Blockquote>

          <Subtitle>Collaborative-Tech.co Service Provision Agreement</Subtitle>
          <Paragraph>Collaborative-Tech.co is a technology service that facilitates communication and interaction  between Clients and Vendors (Experts) to provide their services.</Paragraph>
          <Paragraph>Collaborative-Tech.co does not make decisions related to the provision of Vendor’s services, or otherwise manage or supervise these services or their contents. Collaborative-Tech does not endorse any Expert and only provides the communication channel with those Experts who confirmed their willingness to interact with the Client. The Client is responsible for the assessment and confirmation of the identity and suitability of the Experts reached with the help of Collaborative-Tech.co service. In addition, although we require Experts to provide true and accurate information about themselves, we cannot confirm or test any Expert's input regarding their identity, academic qualifications or experience.</Paragraph>
          <Paragraph>Collaborative-Tech does not provide, produce or procure any illegal services or goods. These include services, products and goods that breach policies and honour codes of educational institutions. The main service of Collaborative-Tech.co is the provision of contact with a Vendor (Expert) and technological means for Experts to provide their service. After the Expert is found for the Client's request, the Client has 30 minutes to reject the Expert and initiate the search for another one free of charge. The Client can request the provision of another Expert or request cancellation at any time after 30 minutes has passed; however, in this case, the Client may need to pay for already provided services.</Paragraph>
          <Paragraph>If the Client is not satisfied with the service provided, they can initiate service improvement free of charge during the period of 30 days after service provision. Service improvement is the alteration of the services, goods or products, which is made by the Vendor (Expert) to match Client's requirements. The Client agrees to provide at least 24 hours for the Vendor to deliver the improved services, products or goods. Should the Vendor be unable to improve the service or refuse to do so, the Client is eligible for 100% refund.</Paragraph>
          <Paragraph>If there are no services provided and/or no Expert is found for the Client's request, the Client is eligible for an immediate 100% refund to their payment method. The Client may also request a refund during the period of 30 days after service or goods provision in case there are issues with services or goods quality or quantity. In the case of quality-related requests, Collaborative-Tech.co will need up to 2 business days to investigate the issue and determine refund eligibility and the amount of refund. It may require 2-5 bank days for refunded funds to reflect in bank statements.</Paragraph>
          <Paragraph>The Client must note that the Vendor (Expert) may also reject the Client's request within 30 minutes free of charge.</Paragraph>
          {/* <Paragraph>Legal address and name of the company: OZ Research Inc, with its registered office at 2043 W 6TH STREET,11223 NEW YORK, USA.</Paragraph> */}

          <Subtitle>Registration and Account Security</Subtitle>
          <Paragraph>Collaborative-Tech.co users provide their real names and information, and we need your help to keep it that way. Here are some commitments you make to us relating to registering and maintaining the security of your account:</Paragraph>
          <List>
            <Item>You will not provide any false personal information on Collaborative-Tech.co, or create an account for anyone other than yourself without permission.</Item>
            <Item>You will not create more than one personal account.</Item>
            <Item>If we disable your account, you will not create another one without our permission.</Item>
            <Item>You will keep your contact information accurate and up-to-date.</Item>
            <Item>You will not share your password (or in the case of developers, your secret key), let anyone else access your account, or do anything else that might jeopardize the security of your account.</Item>
          </List>
          <Paragraph>If you select a username or similar identifier for your account or Page, we reserve the right to remove or reclaim it if we believe it is appropriate (such as when a trademark owner complains about a username that does not closely relate to a user's actual name).</Paragraph>

          <Subtitle>Safety</Subtitle>
          <Paragraph>We do our best to keep Collaborative-Tech.co safe, but we cannot guarantee it. We need your help to keep Collaborative-Tech.co safe, which includes the following commitments by you:</Paragraph>
          <List>
            <Item>You will not post unauthorized commercial communications (such as spam) on Collaborative-Tech.co.</Item>
            <Item>You will not collect users' content or information, or otherwise access Collaborative-Tech.co, using automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior permission.</Item>
            <Item>You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on Collaborative-Tech.co.</Item>
            <Item>You will not upload viruses or other malicious code.</Item>
            <Item>You will not solicit login information or access an account belonging to someone else.</Item>
            <Item>You will not bully, intimidate, or harass any user.</Item>
            <Item>You will not post content that: is hate speech, threatening, or pornographic; incites violence or contains nudity or graphic or gratuitous violence.</Item>
            <Item>You will not use Collaborative-Tech.co to do anything unlawful, misleading, malicious, or discriminatory.</Item>
            <Item>You will not do anything that could disable, overburden, or impair the proper working or appearance of Collaborative-Tech.co, such as a denial of service attack or interference with page rendering or other Collaborative-Tech.co functionality.</Item>
            <Item>You will not facilitate or encourage any violations of this Statement or our policies.</Item>
          </List>

          <Subtitle>Content Regulations</Subtitle>
          <Paragraph>Users are NOT allowed to post content which</Paragraph>
          <List>
            <Item>contains expletives or language which could be deemed offensive or is likely to harass, upset, embarrass, alarm or annoy any other person;</Item>
            <Item>is obscene or otherwise may offend human dignity;</Item>
            <Item>is abusive, insulting or threatening, or which promotes or encourages racism, sexism, hatred or bigotry;</Item>
            <Item>incorporates the image or likeness of any individual under 18 years of age;</Item>
            <Item>is defamatory;</Item>
            <Item>relates to commercial activities (including, without limitation, sales, competitions and advertising, links to other websites or premium line telephone numbers);</Item>
            <Item>involves the transmission of "junk" mail or "spam";</Item>
            <Item>contains any spyware, adware, viruses, corrupt files, worm programs or other malicious code designed to interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications, networks, servers or other equipment,</Item>
            <Item>Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any data or personal information whether from Collaborative-Tech.co or otherwise;</Item>
            <Item>or itself, or the posting of which, infringes any third party's rights (including, without limitation, intellectual property rights, and privacy rights).</Item>
          </List>

          <Subtitle>Collaborative-Tech.co Operation and Liability Regarding Use of the Site</Subtitle>
          <Paragraph>The users bear liability for their own actions in connection with the creation and posting of information on their own personal page on the site as well as in connection with the posting of information on the personal pages of other users and other sections of the site in accordance with effective U.S. legislation. The breach of these Terms and effective U.S. legislation involves civil, administrative, and criminal liability.</Paragraph>
          <Paragraph>Collaborative-Tech.co makes it technically possible for the Users to use the site, does not participate in forming the content of the Users' personal pages, does not control and does not bear liability for the actions or omissions of any persons regarding use of the site or formation and use of the content of the Users' personal pages on the site.</Paragraph>
          <Paragraph>The information system of the site and its software do not contain any technical solutions for the automatic censorship and control of actions and information relations of the users regarding the use of the site.</Paragraph>
          <Paragraph>Collaborative-Tech.co reserves the right to change at any time the Site design, content, list of services, modify or supplement scripts, software, and other objects used or stored on the site, any server applications at any time with or without advance notice.</Paragraph>
          <Paragraph>Collaborative-Tech.co does not carry out preliminary moderation or censorship of the Users' information and takes actions to protect the rights and interests of persons and ensure compliance with the requirements of U.S. legislation only after the person concerned applies duly to Collaborative-Tech.co.</Paragraph>
          <Paragraph>Collaborative-Tech.co does not bear liability for the User's breach of these Terms and reserves the right, at its own discretion as well as upon receipt of information from other Users or third parties on the User's breach of these Terms, to modify (moderate) or remove any information published by the User in breach of the prohibitions set by these Terms (including personal messages), suspend, limit or terminate the User's access to all or any sections or services of the site at any time for any reason or without explaining the reason, with or without advance notice, without being liable for any damage that such action can cause to the User. Collaborative-Tech.co reserves the right to remove the User's personal page and/or suspend, limit or terminate the User's access to any of the Site services, if Collaborative-Tech.co believes that the User poses a threat to the site and/or its Users. Collaborative-Tech.co does not bear liability for temporarily blocking or removing information or removing the User's personal page (registration termination) in accordance with these Terms.</Paragraph>
          <Paragraph>The removal of the User's personal page means an automatic removal of all information posted entered by the User at the time of registration at the site. After the personal page is removed, the User has no longer the right of access to the site.</Paragraph>
          <Paragraph>Collaborative-Tech.co ensures the operation and serviceability of the site. It commits to restoring its serviceability and operations as soon as possible in case of technical failures and technical service interruptions. Collaborative-Tech.co does not bear liability for temporary failures and interruptions in the Site operation and resultant losses of information. Collaborative-Tech.co does not bear liability for any damage to the computer of the User or any third party, mobile devices, any other equipment or software caused by or connected with the download of materials from the site or via the links available on the site.</Paragraph>

          <Subtitle>Disclaimer</Subtitle>
          <Paragraph>The materials on Collaborative-Tech.co site are provided "as is." Collaborative-Tech.co makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Collaborative-Tech.co does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.</Paragraph>
          <Paragraph>The materials appearing on Collaborative-Tech.co web site could include technical, typographical, or photographic errors. Collaborative-Tech.co does not warrant that any of the materials on its web site are accurate, complete, or current. Collaborative-Tech.co may make changes to the materials contained on its web site at any time without notice. Collaborative-Tech.co does not, however, make any commitment to update the materials.</Paragraph>

          <Subtitle>Links</Subtitle>
          <Paragraph>Collaborative-Tech.co has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Collaborative-Tech.co of the site. Use of any such linked web site is at the User's own risk.</Paragraph>

          <Subtitle>Site Terms of Use Modifications</Subtitle>
          <Paragraph>Collaborative-Tech.co may revise these terms of use for its web site at any time without notice. By using this web site, you agree to be bound by the then current version of these Terms and Conditions of Use.</Paragraph>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default injectSheet(styles)(TermsAndConditions)