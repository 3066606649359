import React, { Fragment, useState, useEffect } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import MobileMenu from './../../sections/MobileMenu'
import theme, { rem, content, adaptive, desktop } from '../../theme/base'

const styles = {
  headerRelative: {
    display: 'none',
    [desktop]: {
      display: 'block',
    },
  },
  headerFixed: {
    background: theme.colors.white,
    height: rem(56),
    boxShadow: `0 ${rem(4)} ${rem(8)} 0 rgba(86, 92, 107, 0.1)`,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    display: 'none',
    [adaptive]: {
      display: 'block',
    },
  },
  hamburger: {
    width: rem(24),
    height: rem(18),
    position: 'absolute',
    top: rem(18),
    right: rem(16),
    display: 'none',
    cursor: 'pointer',
    zIndex: 2,
    [adaptive]: {
      display: 'block',
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  getTutor: {
    position: 'absolute',
    top: rem(8),
    right: rem(16),
    width: rem(156),
    height: rem(40),
    padding: [rem(9), 0, rem(7)],
    '&:hover': {
      color: theme.colors.white,
    },
  },
  logo: {
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'absolute',
    top: rem(15),
    left: rem(40),
    zIndex: 2,
    [adaptive]: {
      left: rem(16),
    },
    '&:hover': {
      color: theme.colors.white,
    },
    '& img': {
      width: rem(188),
      height: rem(28),
      display: 'block',
      '&.hasFixedState': {
        width: rem(160),
        height: rem(24),
      },
    },
  },
  actions: {
    position: 'absolute',
    top: rem(8),
    right: rem(40),
    zIndex: 2,
  },
  login: {
    marginRight: rem(24),
    padding: [rem(6), 0, rem(4)],
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(20),
    lineHeight: rem(24),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: rem(0.12),
    textAlign: 'center',
    color: theme.colors.white,
    border: [rem(3), 'solid', 'transparent'],
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      display: 'none',
    },
    '&:hover': {
      color: theme.colors.white,
    },
    '&.hasFixedState': {
      color: theme.colors.blue,
    },
  },
  signup: {
    width: rem(144),
    padding: [rem(6), 0, rem(4)],
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(20),
    lineHeight: rem(24),
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: rem(0.12),
    textAlign: 'center',
    color: theme.colors.white,
    border: [rem(3), 'solid', theme.colors.white],
    borderRadius: rem(20),
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      display: 'none',
    },
    '&:hover': {
      color: theme.colors.white,
    },
    '&.hasFixedState': {
      borderColor: theme.colors.blue,
      color: theme.colors.blue,
    },
  },
  content,
}

const Header = ({ 
  classes,
  hasFixedState,
  customRelativeClassName,
  showLogin,
}) => {
  const [scrolled, setScrolled] = useState(false)
  const [openedMenu, setOpenedMenu] = useState(false)

  const onOpenMenu = () => setOpenedMenu(true)
  const onCloseMenu = () => setOpenedMenu(false)

  const onScroll = () => setScrolled(window.pageYOffset > 0)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const isFixedHeader = scrolled || hasFixedState

  return (
    <Fragment>
      <div className={classnames(classes.headerRelative, customRelativeClassName)}>
        <div className={classes.hamburger} onClick={onOpenMenu}>
          <img src={require('./hamburger.svg')} alt='' />
        </div>
{/*
        <Link className={classes.logo} to='/'>
          <img 
            className={classnames({ hasFixedState })} 
            src={require(`./logo-${hasFixedState ? 'blue': 'light'}.svg`)} alt='' 
          />
        </Link>
*/}
        {showLogin &&
          <div className={classes.actions}>
            <span className={classnames(classes.login, { hasFixedState })}>Login</span>
            <span className={classnames(classes.signup, { hasFixedState })}>Sign up</span>
          </div>
        }
      </div>
      {isFixedHeader &&
        <div className={classes.headerFixed}>
{/*
          <Link className={classes.logo} to='/'>
            <img className='hasFixedState' src={require('./logo-blue.svg')} alt='' />
          </Link>
*/}
          <div className={classes.hamburger} onClick={onOpenMenu}>
            <img src={require('./hamburger-grey.svg')} alt='' />
          </div>
          {/* <div className={classes.getTutor} onClick={onScrollToForm}>Get a&nbsp;tutor</div> */}
        </div>
      }
      <MobileMenu 
        opened={openedMenu} 
        showLogin={showLogin}
        onClose={onCloseMenu} 
      />
    </Fragment>
  )
}

export default injectSheet(styles)(Header)